import { Directive, ElementRef, inject, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { skipNullish } from '@icp/angular/rxjs-operators';
import { TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map } from 'rxjs';

@UntilDestroy()
@Directive({
    selector: '[icpErrorLabel]',
    standalone: true,
})
export class IcpErrorLabelDirective implements OnInit {
    @Input({ required: true }) public icpErrorLabel: AbstractControl;

    private transloco = inject(TranslocoService);
    private elementRef = inject(ElementRef<HTMLElement>);

    public ngOnInit(): void {
        this.icpErrorLabel.statusChanges
            .pipe(
                untilDestroyed(this),
                map((status) => {
                    if (status === 'INVALID') {
                        if (this.icpErrorLabel.hasError('required')) {
                            return this.transloco.translate('THIS_FIELD_IS_REQUIRED');
                        } else if (this.icpErrorLabel.hasError('email')) {
                            return this.transloco.translate('INVALID_EMAIL_ADDRESS');
                        } else if (this.icpErrorLabel.hasError('vat')) {
                            return this.transloco.translate('ERROR_NOT_A_VALID_VAT_NUMBER');
                        } else if (this.icpErrorLabel.hasError('url')) {
                            return `${this.transloco.translate('THIS_FIELD_IS_INVALID')} ${this.transloco.translate('HINT_CORRECT_WEBSITE_URL')}`;
                        } else {
                            return this.transloco.translate('THIS_FIELD_IS_INVALID');
                        }
                    }
                    return null;
                }),
                distinctUntilChanged(),
                skipNullish,
            )
            .subscribe((errorLabel) => (this.elementRef.nativeElement.innerText = errorLabel));
    }
}
